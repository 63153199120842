import React from 'react';
import useCurrentWidth from '../../../hooks/useGrid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabPanel from '../../Tabs/TabPanel';

function BlogPageTabs(props) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const percentage = useCurrentWidth().percentage;

    return (
        <div className='blog_page_tabs'>
            
            <div className='tabs_wrapper'>
                <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
                    <div className='tabs'>
                        <Tabs value={value} onChange={handleChange} aria-label="example">

                            {props.tabs && props.tabs.length ? props.tabs.map((key, idx) => {

                            return(
                                <Tab label={key.label} key={idx} />
                            )

                            }) : null}

                        </Tabs>
                    </div>
                </div>
            </div>


            {props.tabpanels && props.tabpanels.length ? props.tabpanels.map((key, idx) => {

                return(
                    <TabPanel value={value} index={key.index} dir={'ltr'} key={idx}>
                        {key.component}
                    </TabPanel>
                )

            }) : null}

        </div>
    );
}

export default BlogPageTabs;