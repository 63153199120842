import React from 'react';
import { Route, Routes } from 'react-router-dom';
import useCurrentWidth from '../../hooks/useGrid';

import LibraryTabItemsRoute from './LibraryTabItemsRoute';
import LibraryItemRoute from './LibraryItemRoute';

import PageLoader from '../Loaders/PageLoader';

function ResearchPageLibraryTab(props) {

    const percentage = useCurrentWidth().percentage


    return (
        <>

            <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
                <div className='research_page_sections'>

                    <React.Suspense fallback={<PageLoader />}>
                        <Routes>

                            <Route path="/" element={<LibraryTabItemsRoute />} />
                            <Route path="/item" element={<LibraryItemRoute />} />

                        </Routes>
                    </React.Suspense>

                </div>
            </div>
        
        </>
    );
}

export default ResearchPageLibraryTab;