import React from 'react'

// routes

const Home = React.lazy(() => import('../Pages/HomePage'))
const About = React.lazy(() => import("../Pages/AboutPage"))
const Contact = React.lazy(() => import("../Pages/ContactPage"))
const Technology = React.lazy(() => import("../Pages/TechnologyPage"))
const Research = React.lazy(() => import("../Pages/ResearchPage"))

const NotFound = React.lazy(() => import('../Pages/NotFound'))

const routes = [
    
  { path: '/', exact: true, name: 'Home', component: <Home /> },
  { path: '/about', exact: true, name: 'About', component: <About /> },
  { path: '/contact', exact: true, name: 'Contact', component: <Contact /> },
  { path: '/technology', exact: true, name: 'Contact', component: <Technology /> },
  // { path: '/research', exact: true, name: 'Contact', component: <Research /> },

  { path: '*', component : <NotFound />},

]

export default routes
