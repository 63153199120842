import React from 'react';

import {
    Routes,
    Route,
} from "react-router-dom";

import routes from '../../Routes/routes';

// import PageContentLoader from '../../Components/Loaders/PageContentLoader';
import PageLoader from '../../Components/Loaders/PageLoader'


function Content(props) {


    return (
        <React.Suspense fallback={<PageLoader />}>
            <Routes>

                {routes.map((route, idx) => {
                    return (
                        route.component && (
                        <Route 
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            key={idx}
                            element={route.component}
                        />
                        )
                    )
                })}
                

            </Routes>
        </React.Suspense>
    );
}

export default Content;