import React from 'react';
import { Route, Routes } from 'react-router-dom';


import PageTitle from '../Components/PageTitle';

import Header from '../Components/Header';
import Footer from '../Components/Footer';

import PageLoader from '../Components/Loaders/PageLoader'

import MainPart from '../Components/CareerPageComponents/MainPart';
import DescriptionPage from '../Components/CareerPageComponents/DescriptionPage';

function CareerPage(props) {


    return (
        <div className='career_page'>
            
            <Header />
       
            <div className='page_wrapper'>
                <PageTitle title="Career" />

                <React.Suspense fallback={<PageLoader />}>
                    <Routes>

                        <Route path="/" element={<MainPart />} />
                        <Route path="/description" element={<DescriptionPage />} />

                    </Routes>
                </React.Suspense>
            </div>

            <div className='mb_150'></div>

            <Footer />

        </div>
    );
}

export default CareerPage;