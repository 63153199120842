import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useCurrentWidth from '../hooks/useGrid';

import Logo from './../assets/Logo/header_logo.svg'

import { AiOutlineMenu } from 'react-icons/ai'
import { BsXLg } from 'react-icons/bs'

import Animation from './Animations/Animation';

function Header(props) {

    const percentage = useCurrentWidth().percentage


    const currentWidth = useCurrentWidth().windowWidth
    // handling mobie menu
    const [mobileMenuOpened, setSetMobileMenu] = useState(false);

    const clickMenuButton = () => {
        setSetMobileMenu(!mobileMenuOpened);
    }

    const remove_overlay = () => {
        setSetMobileMenu(false);
    }
    useEffect(() => {
        remove_overlay()
    }, [])


    const animate = {
        visible: {opacity: 1, width: "auto", height:'auto', transition: { duration: .4 }},
        hidden: { opacity: 0, height: 0 }
    }

    return (
        <>
            <div className='Header'>
                <div className='header_wrapper jc_sb flex' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>

                    <div className={currentWidth < 992 ? 'left flex ai_center mobile_menu' : 'left flex ai_center'}>

                        <div className='logo'>
                            <NavLink to={'/'}>
                                <img src={Logo} alt='NEXTXEN' />
                            </NavLink>
                        </div>

                        {currentWidth > 991 ?
                            <div className='link_items flex ai_center'>
                                <div className='item'>
                                    <NavLink to={'/technology'}>
                                        Technology
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/about'}>
                                        About
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/research'}>
                                        Research
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/blog'}>
                                        Blog
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/contact'}>
                                        Contact
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/career'}>
                                        Career
                                    </NavLink>
                                </div>
                            </div>
                        :
                            <div className='menu_bar'>
                                <Button onClick={clickMenuButton}>
                                    {mobileMenuOpened ? <BsXLg /> : <AiOutlineMenu />}
                                </Button>
                            </div>
                        }

                    </div>

                    {currentWidth > 991 ?
                        <div className='right flex ai_center'>
                            <div className='item active'>
                                <NavLink to={'#'}>
                                    中文
                                </NavLink>
                            </div>
                            <div className='item'>
                                <NavLink to={'#'}>
                                    EN
                                </NavLink>
                            </div>
                        </div>
                    :
                        null 
                    }

                </div>
            </div>


           {currentWidth < 992 && mobileMenuOpened ?
               <div className='mobile_menus_w'>
                   <div className='overlay' onClick={clickMenuButton}></div>
                    <Animation animate={animate}>
                        <div className='wrapper'>
                            <div className='link_items flex ai_center'>
                                <div className='item'>
                                    <NavLink to={'/technology'}>
                                        Technology
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/about'}>
                                        About
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/research'}>
                                        Research
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/blog'}>
                                        Blog
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/contact'}>
                                        Contact
                                    </NavLink>
                                </div>
                                <div className='item'>
                                    <NavLink to={'/career'}>
                                        Career
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </Animation>
               </div>
            :
                null
            }
        </>
    );
}

export default Header;