import one from '../../assets/blogs/01.png';
import two from '../../assets/blogs/02.png'
import three from '../../assets/blogs/03.png'

const blogs = [
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: two
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: three
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },

    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },

    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },

        {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },

    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },
    {
        name: 'Hari Prashad Kamal',
        date: 'February 7, 2022',
        time: '4 mins read',
        subtitle: 'Token, Games, News, Vorld, Article....',
        title: 'Vorld collaboration in NFTs, Games, and the Metaverse',
        img: one
    },

]

export default blogs