import React from 'react';
import useCurrentWidth from '../../hooks/useGrid';

import Animation from '../Animations/Animation';

import KYPitch from '../../assets/partners/ResearchPartners/yale.png'
import NKU from '../../assets/partners/ResearchPartners/NKU.png'

function ResearchPageAboutTab(props) {

    const percentage = useCurrentWidth().percentage

    const animate = {
        visible: {opacity: 1, position: 'relative', top: 0, transition: { duration: .5 }},
        hidden: { opacity: 0, position: 'relative', top: 100}
    }

    const animate2 = {
        visible: {opacity: 1, scale: 1, transition: { duration: .4 }},
        hidden: { opacity: 0, scale: 0 }
    }


    return (
        <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
            <div className='research_page_sections'>

                <Animation animate={animate}>
                    <div className='about_section item'>
                        <div className='wrapper'>
                            <div className='title' style={{ width: percentage * 20, paddingRight: percentage * 1}}>
                                About
                            </div>
                            <div className='content' style={{ width: percentage * 39, paddingLeft: percentage * 1}}>
                                <div style={{ width: percentage * 29}}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue leo augue dignissim condimentum interdum a, eget fusce. 
                                    Nunc adipiscing elit molestie lobortis at posuere tincidunt nibh. Massa commodo nunc eget odio. Donec imperdiet 
                                    senectus vulputate orci sollicitudin id orci congue.
                                </div>
                            </div>
                        </div>
                    </div>
                </Animation>


                <Animation animate={animate}>
                    <div className='vision_section item'>
                        <div className='wrapper'>
                            <div className='title' style={{ width: percentage * 20, paddingRight: percentage * 1}}>
                                Vision
                            </div>
                            <div className='content' style={{ width: percentage * 39, paddingLeft: percentage * 1}}>
                                <div style={{ width: percentage * 29}}>
                                    Nextxen Research's mission is to become a leading academic institution in the field of fintech blockchain 
                                    infrastructure research. We aim to tackle challenging research questions and build a solid basis for the 
                                    fintech blockchain infrastructure industry.
                                </div>
                            </div>
                        </div>
                    </div>
                </Animation>

                <Animation animate={animate}>
                    <div className='interest_section item'>
                        <div className='wrapper'>
                            <div className='title' style={{ width: percentage * 20, paddingRight: percentage * 1}}>
                                Area Of Interest
                            </div>
                            <div className='content' style={{ width: percentage * 39, paddingLeft: percentage * 1}}>
                                <div style={{ width: percentage * 29}}>
                                    <ul>
                                        <li>Cryptography</li> 
                                        <li>Blockchain gaming</li> 
                                        <li>Distributed Systems</li> 
                                        <li>Human-Computer Interaction</li> 
                                        <li>AI </li> 
                                        <li>Networks</li>
                                        <li>Programming Languages</li> 
                                        <li>Trusted Hardware</li> 
                                        <li>Economics </li> 
                                        <li>Policy and Regulation regarding securities/ foreign exchange/ businesses</li> 
                                        <li>Tax law</li> 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animation>


                <div className='research_partners'>
                    <div className='title center'>
                        Research Partners
                    </div>
                    <div className='wrapper'>
                        <div className='left'>
                            <Animation animate={animate2}>
                                <img src={KYPitch} alt="KYPitch"/>
                            </Animation>
                        </div>
                        <div className='right'>
                            <Animation animate={animate2}>
                                <img src={NKU} alt="NKU"/>
                            </Animation>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default ResearchPageAboutTab;