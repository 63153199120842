import React from 'react';

function BlogElement(props) {
    return (
        <div className='blog_item'>
            <div className='wrapper'>
                <div className='image'>
                    <img src={props.data.img} alt="thumbnail" />
                </div>
                <div className='content'>
                    <div className='sub_title'>
                        {props.data.subtitle}
                    </div>
                    <div className='title'>
                        {props.data.title}
                    </div>
                    <div className='bottom'>
                        <div className='date'>
                            {props.data.date} <span>·</span> {props.data.name}
                        </div>
                        <div className='time'>
                            {props.data.time}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogElement;