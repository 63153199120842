import React, { useEffect } from 'react';
import useCurrentWidth from '../../hooks/useGrid';

import Content from './Content';

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

function MainLayout(props) {


    useEffect(() => {
        document.body.classList.remove('bg_white');
    }, [])

    const percentage = useCurrentWidth().percentage
    const style = {
        width: percentage * 4
    }

    return (
        <div>
            
            {/* <div className='grid_wrapper'>
                <div className='grid' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                    <div style={style}></div>
                </div>
            </div> */}

            <Header />

            <Content />

            <Footer />

        </div>
    );
}

export default MainLayout;