import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentWidth from '../../hooks/useGrid';

import { BsArrowLeft, BsFillFileEarmarkPdfFill } from 'react-icons/bs';


import nextxen_paper from '../../assets/file/nextxen.pdf'

function LibraryItemRoute(props) {

    const navigate = useNavigate();
    const percentage = useCurrentWidth().percentage

    return (
        <div className='library_item_wrapper'>
            <div className='wrapepr' style={{ width: percentage * 39, margin: 'auto' }}>


                <div className='back_btn'>
                    <Button onClick={() => navigate(-1)}>
                        <BsArrowLeft /> &nbsp;go back
                    </Button>
                </div>

                <div className='contents'>

                    <div className='head_part'>
                        <div className='title'>
                            Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                        </div>
                        <div className='sub_title'>
                            Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                        </div>
                        <div className='date'>
                            February 7, 2022 
                        </div>
                    </div>

                    <div className='text_section'>
                        <div className='section'>
                            The Filecoin ecosystem and Polygon Studios are delighted to announce a collaboration to drive growth in the 
                            non-fungible token (NFT), games, and meta verse space. To kickstart the collaboration, the two ecosystems have 
                            developed tutorials, integration grants, and hackathons aimed at helping developers and NFT projects in the Polygon 
                            network to integrate with InterPlanetary File System (IPFS) and Filecoin.
                        </div>
                        <div className='section'>
                            Originally conceived as a secure Layer 2 solution to address pain points like high gas fees and slow block validation 
                            times on popular blockchains like Ethereum, Polygon has gained traction with blockchain game developers and NFT 
                            projects. To cater to this growing segment, Polygon Studios was launched with the goal of incubating and 
                            supporting Web 3.0-powered game developers, and NFT projects.
                        </div>
                        <div className='section'>
                            Similarly, a growing number of projects in the play-to-earn (P2E) and NFT space have also chosen to use IPFS for 
                            content addressing and Filecoin for provable and decentralized storage to store metadata with rich media such as 
                            videos.
                        </div>
                        <div className='section title'>
                            Combining Filecoin’s durability and Polygon’s scalability opens new use cases for developers
                        </div>
                        <div className='section'>
                            In August 2021, we announced that Polygon would offer free storage costs for projects looking to build on the 
                            Filecoin-Polygon bridge developed by the Textile team. The bridge enables developers and Polygon application 
                            users to easily store data from any Polygon address on the IPFS network without any new signups, secrets, 
                            or API keys.
                        </div>
                        <div className='section'>
                            Filecoin and Polygon also jointly-organized the Mars Hackathon, which ran from August to November 2021. The virtual 
                            hackathon saw more than 1,500 developers coming together to build new applications using the Filecoin-Polygon bridge, 
                            including use cases like storage, the metaverse, entertainment, digital art, and more.
                        </div>
                        <div className='section'>
                            Notable projects from the hackathon included Blockspace, a decentralized file storage and sharing platform built on 
                            top of IPFS that mirrors traditional cloud providers like Google Drive, and Slick, a decentralized alternative to 
                            Patreon.
                        </div>
                        <div className='section title'>
                            What You Need to Know about Vorld Product Design Interview and What to do about it
                        </div>
                        <div className='section'>
                            Vinyl hella hot chicken aesthetic, intelligentsia raclette gentrify activated charcoal VHS. Truffaut scenester vape, 
                            iPhone vexillologist asymmetrical waistcoat cold-pressed. Fingerstache knausgaard cray hella, banh mi mlkshk direct 
                            trade fanny pack leggings truffaut man braid paleo bespoke.
                        </div>
                        <div className='section'>
                            Authentic vexillologist thundercats, kale chips next level flannel activated charcoal keffiyeh single-origin 
                            coffee lo-fi swag stumptown marfa dreamcatcher. Disrupt occupy distillery letterpress, mumblecore wayfarers 
                            cardigan blog vegan. Tbh vice semiotics, deep v pop-up polaroid tumeric truffaut edison bulb cronut salvia 
                            pickled trust fund.
                        </div>
                    </div>

                </div>

                <div className='view_paper_button'>
                    <div>
                        <a href={nextxen_paper} target="_blank" download>
                            <Button>
                                <div className='icon'><BsFillFileEarmarkPdfFill /></div> 
                                <div>&nbsp; View full paper</div>
                            </Button>
                        </a>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default LibraryItemRoute;