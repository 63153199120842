import React from 'react';
import useCurrentWidth from '../../hooks/useGrid';
import { Link } from 'react-router-dom';

import oneImage from '../../assets/research/01.png'
import twoImage from '../../assets/research/02.png'
import threeImage from '../../assets/research/03.png'
import fourImage from '../../assets/research/04.png'

import { BsFillTriangleFill } from 'react-icons/bs';
import Animation from '../Animations/Animation';



function ResearchPageVideoTab(props) {

    const percentage = useCurrentWidth().percentage
    const animate = {
        visible: {opacity: 1, scale: 1, transition: { duration: .4 }},
        hidden: { opacity: 0, scale: 0 }
    }


    return (
        <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }} >
            <div className='research_page_sections'>
                <div className='research_page_video_tab'>

                    <div className='video_items'>
                        <Animation animate={animate}>
                            <div className='item' style={{ width: percentage * 14 }}>
                                <Link to="#">
                                    <div className='image' style={{ height: percentage * 9 }}>
                                        <img src={oneImage} alt="one" />
                                        <div className='video_icon'>
                                            <div className='icon'>
                                                <BsFillTriangleFill />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div>File for Research for Library</div>
                                    </div>
                                </Link>
                            </div>
                        </Animation>
                        
                        <Animation animate={animate}>
                            <div className='item' style={{ width: percentage * 14 }}>
                                <Link to="#">
                                    <div className='image' style={{ height: percentage * 9 }}>
                                        <img src={twoImage} alt="one" />
                                        <div className='video_icon'>
                                            <div className='icon'>
                                                <BsFillTriangleFill />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div>File for Research for Library</div>
                                    </div>
                                </Link>
                            </div>
                        </Animation>

                        <Animation animate={animate}>
                            <div className='item' style={{ width: percentage * 14 }}>
                                <Link to={"#"}>
                                    <div className='image' style={{ height: percentage * 9 }}>
                                        <img src={threeImage} alt="one" />
                                        <div className='video_icon'>
                                            <div className='icon'>
                                                <BsFillTriangleFill />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div>File for Research for Library</div>
                                    </div>
                                </Link>
                            </div>
                        </Animation>

                        <Animation animate={animate}>
                            <div className='item' style={{ width: percentage * 14 }}>
                                <Link to={"#"}>
                                    <div className='image' style={{ height: percentage * 9 }}>
                                        <img src={fourImage} alt="one" />
                                        <div className='video_icon'>
                                            <div className='icon'>
                                                <BsFillTriangleFill />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div>File for Research for Library</div>
                                    </div>
                                </Link>
                            </div>
                        </Animation>

                    </div>

                </div>    
            </div>
        </div>
    );
}

export default ResearchPageVideoTab;