import React from 'react';
import useCurrentWidth from '../../hooks/useGrid';

import blogs from './blogs';
import BlogElement from './BlogElement';

import Animation from '../Animations/Animation';
import { Link } from 'react-router-dom';

function BlogsItems(props) {
 
    const percentage = useCurrentWidth().percentage;

    
    const animate = {
        visible: {opacity: 1, scale: 1, transition: { duration: .4 }},
        hidden: { opacity: 0, scale: 0 }
    }

    return (
        <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
            <div className='blog_items'>
                <div className='wrapper'>

                    {blogs && blogs.length ? 

                        blogs.map((key, idx) => {
                            return(
                                <Animation animate={animate} key={idx} style={{ width: percentage * 19 }}>
                                    <Link to={"/blog/item"}>
                                        <BlogElement data={key} />
                                    </Link>
                                </Animation>
                            )
                        })
                    
                    : null}

                </div>
            </div>
        </div>
    );
}

export default BlogsItems;