import React from 'react';

function PageLoader(props) {
    return (
        <div className='page_content_loader'>
            <div className='wrapper'>
                <div className="lds-dual-ring"></div> 
            </div>
        </div>
    );
}

export default PageLoader;