import React from 'react';
import useCurrentWidth from '../../hooks/useGrid';

function BlogPageHead(props) {

    const percentage = useCurrentWidth().percentage;

    return (
        <div className='blog_page_head'>
            <div className='bg_image' style={{backgroundImage: `url(${props.image})`}}>
               <div className='full_wrapper'>
                <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
                        <div className='wrapper' style={{ width: percentage * 24, marginLeft: percentage * 5 }}>
                            {props.children}
                        </div>
                    </div>
               </div>
            </div>
        </div>
    );
}

export default BlogPageHead;