import React from 'react';
import Particles from 'react-tsparticles'

function HeroParticles(props) {

    const particlesInit = (main) => {
        // console.log(main);
    };

    const particlesLoaded = (container) => {
        // console.log(container);
    };

    return (
        <div className="heroParticlesWrapper">
            <Particles
                canvasClassName="canvas"
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                
                options= {{
                    "background": {
                        "color": {
                        "value": "transparent"
                        },
                        "position": "50% 50%",
                        "repeat": "no-repeat",
                        "size": "cover"
                    },
                    "fullScreen": {
                        enable: false,
                        "zIndex": 1
                    },
                    "interactivity": {
                      "events": {
                        "onClick": {
                          "enable": true,
                          "mode": "push"
                        },
                        "onHover": {
                          "enable": true,
                          "mode": "grab",
                          "parallax": {
                            "enable": true,
                            "force": 60
                          }
                        }
                      },
                      "modes": {
                        "bubble": {
                          "distance": 100,
                          "duration": 2,
                          "opacity": 0.2,
                          "size": 40
                        },
                        "grab": {
                          "distance": 100
                        }
                      }
                    },
                    "particles": {
                      "color": {
                        "value": "#287BDD"
                      },
                      "links": {
                        "color": {
                          "value": "#287BDD"
                        },
                        "distance": 100,
                        "enable": true,
                        "opacity": 0.2
                      },
                      "move": {
                        "attract": {
                          "rotate": {
                            "x": 600,
                            "y": 600
                          }
                        },
                        "enable": true,
                        "path": {},
                        "outModes": {
                          "bottom": "out",
                          "left": "out",
                          "right": "out",
                          "top": "out"
                        },
                        "spin": {}
                      },
                      "number": {
                        "density": {
                          "enable": true
                        }
                      },
                      "opacity": {
                        "random": {
                          "enable": true
                        },
                        "value": {
                          "min": 0.1,
                          "max": 0.5
                        },
                        "animation": {
                          "enable": true,
                          "speed": 2,
                          "minimumValue": 0.1
                        }
                      },
                      "size": {
                        "random": {
                          "enable": true
                        },
                        "value": {
                          "min": 0.1,
                          "max": .5
                        },
                        "animation": {
                          "enable": true,
                          "speed": 2,
                          "minimumValue": 0.1
                        }
                      }
                    }
                }}

                // options={{
                //     "background": {
                //         "color": {
                //         "value": "transparent"
                //         },
                //         "position": "50% 50%",
                //         "repeat": "no-repeat",
                //         "size": "cover"
                //     },
                //     "fullScreen": {
                //         enable: false,
                //         "zIndex": 1
                //     },
                //     "interactivity": {
                //         "events": {
                //         "onClick": {
                //             "enable": true,
                //             "mode": "push"
                //         },
                //         "onDiv": {
                //             "selectors": "#repulse-div",
                //             "mode": "repulse"
                //         },
                //         "onHover": {
                //             "enable": true,
                //             "mode": "connect",
                //             "parallax": {
                //             "force": 60
                //             }
                //         }
                //         },
                //         "modes": {
                //         "bubble": {
                //             "distance": 400,
                //             "duration": 2,
                //             "opacity": 0.8,
                //             "size": 40
                //         },
                //         "grab": {
                //             "distance": 400
                //         }
                //         }
                //     },
                //     "particles": {
                //         "color": {
                //         "value": "random"
                //         },
                //         "links": {
                //         "color": {
                //             "value": "#ffffff"
                //         },
                //         "distance": 150,
                //         "opacity": 0.4
                //         },
                //         "move": {
                //         "attract": {
                //             "rotate": {
                //             "x": 600,
                //             "y": 1200
                //             }
                //         },
                //         "enable": true,
                //         "outModes": {
                //             "bottom": "out",
                //             "left": "out",
                //             "right": "out",
                //             "top": "out"
                //         },
                //         "speed": 1
                //         },
                //         "number": {
                //         "density": {
                //             "enable": true
                //         },
                //         "limit": 500,
                //         "value": 300
                //         },
                //         "opacity": {
                //         "value": 0.5,
                //         "animation": {
                //             "speed": 5000,
                //             "minimumValue": 0.1
                //         }
                //         },
                //         "size": {
                //         "random": {
                //             "enable": true
                //         },
                //         "value": {
                //             "min": 1,
                //             "max": 5
                //         },
                //         "animation": {
                //             "speed": 40,
                //             "minimumValue": 0.1
                //         }
                //         }
                //     }
                // }}


            />
        </div>
    );
}

export default HeroParticles;