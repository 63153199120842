import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabPanel from './TabPanel';

function TabMenu(props) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
        <div className='tabs'>
          <Tabs value={value} onChange={handleChange} aria-label="example">

              {props.tabs && props.tabs.length ? props.tabs.map((key, idx) => {

                return(
                  <Tab label={key.label} key={idx} />
                )

              }) : null}

          </Tabs>
        </div>

        
        {props.tabpanels && props.tabpanels.length ? props.tabpanels.map((key, idx) => {

          return(
            <TabPanel value={value} index={key.index} dir={'ltr'} key={idx}>
               {key.component}
            </TabPanel>
          )

        }) : null}

    </>
  );
}

export default TabMenu