import React from 'react';
import { Link } from 'react-router-dom';
import useCurrentWidth from '../../hooks/useGrid';

import Animation from '../Animations/Animation';

function LibraryTabItemsRoute(props) {

    const percentage = useCurrentWidth().percentage

    const animate = {
        visible: {opacity: 1, scale: 1, transition: { duration: .4 }},
        hidden: { opacity: 0, scale: 0 }
    }

    return (
        <div className='library_wrapper'>

            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>

            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>


            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>


            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>

            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>


            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>


            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>
            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>
            <Animation animate={animate}>
                <div className='card_item' style={{ width: percentage * 14 }}>
                    <Link to={'/research/item'}>
                        <div className='image' style={{ height: percentage * 6.3 }}>
                            <div className='title'>
                                Efficient Random Beacons with Adaptive Security for Ungrindable Blockchains 
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text'>
                                Seyed Hossein Haeri, Peter Thompson, Neil Davies, Peter Van Roy, Kevin Hammond, James Chapman
                            </div>
                            <div className='date'>
                                February 7, 2022
                            </div>
                        </div>
                    </Link>
                </div>
            </Animation>
            
            

        </div>
    );
}

export default LibraryTabItemsRoute;