import { useState, useEffect } from 'react';

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

function useCurrentWidth() {

  let [width, setWidth] = useState(getWidth());


  useEffect(() => {
      
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  var windowWidth = width;
  if(windowWidth > 1500) {
      windowWidth = 1500
  }
  const main_padding = (3.1746031746 / 100) * windowWidth;
  const main_width = windowWidth - (main_padding * 2);
  const single_percentage_of59 = main_width / 59

  const value = {main_padding: main_padding, main_width: main_width, windowWidth: windowWidth, percentage: single_percentage_of59}

  return value;
}


export default useCurrentWidth