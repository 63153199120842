import React from 'react';
import { Link } from 'react-router-dom';
import useCurrentWidth from '../hooks/useGrid';

import { FaFacebook, FaGithub, FaTelegram, FaDiscord, FaTwitter } from 'react-icons/fa';

import Animation from './Animations/Animation';
import Logo from './../assets/Logo/header_logo.svg'


function Footer(props) {

    const animate = {
        visible: {opacity: 1, scale: 1, transition: { duration: .4 }},
        hidden: { opacity: 0, scale: 0 }
    }

    const percentage = useCurrentWidth().percentage;

    return (
        <div className='footer'>
            
            <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
                <Animation animate={animate}>

                    <div className='footer_wraper'>

                        <div className='left'>
                            <div className='wrapper'>
                                <div className='logo'>
                                    <img src={Logo} alt="NEXTXEN" />
                                </div>
                                <div className='title'>
                                    NEXTXEN
                                </div>
                            </div>
                        </div>

                        <div className='right'>

                            <div className='items'>
                                <div className='item title'>
                                    Technology
                                </div>
                                <div className='item'>
                                    <Link to="/">Vorld</Link>
                                </div>
                                <div className='item'>
                                    <Link to="/">Primo Wallet</Link>
                                </div>
                                <div className='item'>
                                    <Link to="/">Identity</Link>
                                </div>
                            </div>

                            <div className='items'>
                                <div className='item title'>
                                    Resources
                                </div>
                                <div className='item'>
                                    <Link to="/">Research</Link>
                                </div>
                                <div className='item'>
                                    <Link to="/">Blog</Link>
                                </div>
                                <div className='item'>
                                    <Link to="/">Press</Link>
                                </div>
                                <div className='item'>
                                    <Link to="/">GitHub</Link>
                                </div>
                                <div className='item'>
                                    <Link to="/">Course</Link>
                                </div>
                            </div>

                            <div className='items'>
                                <div className='item title'>
                                    Legal
                                </div>
                                <div className='item'>
                                    <Link to="/">Privacy Policy</Link>
                                </div>
                                <div className='item'>
                                    <Link to="/">Brand Assets</Link>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='social_icons'>
                        <div className='items'>
                            <div className='item'>
                                <a  href={ "https://twitter.com"} rel="noreferrer"  target="_blank">
                                    <FaTwitter />
                                </a>
                            </div>
                            <div className='item'>
                                <a href={ "https://telegram.com"} rel="noreferrer"  target="_blank">
                                    <FaTelegram />
                                </a>
                            </div>
                            <div className='item'>
                                <a href={ "https://discord.com"} rel="noreferrer"  target="_blank">
                                    <FaDiscord />
                                </a>
                            </div>
                            <div className='item'>
                                <a href={ "https://github.com" } rel="noreferrer"  target="_blank">
                                    <FaGithub />
                                </a>
                            </div>
                            <div className='item'>
                                <a href={ "https://facebook.com"} rel="noreferrer"  target="_blank">
                                    <FaFacebook />
                                </a>
                            </div>
                        </div>
                    </div>


                    <div className='copyright'>
                        ©2022 Nextxen, All rights reserved
                    </div>

                </Animation>
            </div>

        </div>
    );
}

export default Footer;