import React, { useEffect } from 'react';

import bg_image from '../../assets/PageContent/blog_head.png'

import {Button} from '@mui/material'

import BlogPageHead from './BlogPageHead';
import BlogPageTabs from './BlogPageTab/BlogPageTabs';

import BlogsItems from './BlogsItems';
import { Link } from 'react-router-dom';


function BlogPageBlogs(props) {


    const headContents = <div className='Head_content_wrapper'>
        <div className='subtitle'>Token, Games, News, Vorld, Article....</div>
        <div className='title'>Vorld collaboration in NFTs, Games, and the Metaverse</div>
        <div className='button'>
            <Link to={"/blog/item"}>
                <Button>Read More</Button>
            </Link>
        </div>
    </div>


    const tabs = [{label: 'All'},{label: 'Events'}, {label: 'Interviews'}, {label: 'Updates'}, {label: 'Search'}]
    const tabpanels = [{component: <BlogsItems />, index: 0}, {component: <BlogsItems />, index: 1}]

    return (
        <div className='blog_page'>

            <BlogPageHead image={bg_image}>{headContents}</BlogPageHead>

            <BlogPageTabs tabs={tabs} tabpanels={tabpanels} />
            

            <div className='mb_150'></div>
        </div>
    );
}

export default BlogPageBlogs;