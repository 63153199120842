import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentWidth from '../../hooks/useGrid';

import { BsArrowLeft } from 'react-icons/bs';
import {FiChevronDown} from 'react-icons/fi'
import { Button } from '@mui/material';

import Animation from '../Animations/Animation';

function DescriptionPage(props) {

    const percentage = useCurrentWidth().percentage    
    const navigate = useNavigate();


    const animate = {
        visible: {opacity: 1, width: "auto", height:'auto', transition: { duration: .4 }},
        hidden: { opacity: 0, height: 0 }
    }


    const [show_form, set_show_form] = useState(false);
    const show_remove_form = () => {
        set_show_form(!show_form)
    }

    return (
        <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
            <div className='description_page career_main_wrapper' style={{ width: percentage * 29 }}>

                <div className='back_btn'>
                    <Button onClick={() => navigate(-1)}>
                        <BsArrowLeft /> &nbsp;back
                    </Button>
                </div>


                <div className='description'>

                    <div className='title'>Business Analyst</div>

                    <div className='text_section'>
                        IO Global is searching for a Business Analyst to join its Corporate Strategy team. As a BA at IOG, 
                        you will work closely with Business Intelligence analysts and the wider Corporate Strategy team to 
                        complement and supplement research deliverables. Research inputs will play a role in wider research initiatives, 
                        such as market trends or product gaps. <br />
                        A BA in the Corporate Strategy team will also help coordinate and communicate research to other teams, including senior leadership. 
                    </div>

                    <div className='title_sub'>Your mission</div>

                    <div className='text_section'>
                        Work with Business Intelligence analysts to understand research needs and contribute to overall research initiatives. <br />
                        Communicate with the Corporate Strategy team as it relates to research needs as well as research collateral. <br />
                        Provide operational support for the BI team. <br />
                        Collect research requirements for projects and proposals to be reviewed by executive committees.
                    </div>

                    <div className='title_sub'>Requirements</div>

                    <div className='text_section'>
                        At least three years experience as a Business Analyst. <br />
                        Experience with working with research companies or research departments within an organization. <br />
                        Minimum Bachelor’s degree.
                    </div>


                    <div className='apply_form_wrapper'>
                        <div className='wrapper'>
                            <div className='head' onClick={show_remove_form}>
                                <div className='text'>
                                    Apply Form
                                </div>
                                <div className={show_form ? 'icon show' : 'icon'}>
                                    <FiChevronDown />
                                </div>
                            </div>

                            {show_form ? 
                                <Animation animate={animate}>
                                    <div className='form_wrapper'>
                                        <div className='form_group'>
                                            <label>Full Name</label>
                                            <input type={"text"} placeholder={"Full Name"} />
                                        </div>
                                        <div className='form_group'>
                                            <label>Email</label>
                                            <input type={"email"} placeholder={"Email"} />
                                        </div>
                                        <div className='form_group'>
                                            <label>Upload Resume</label>
                                            <input type={"file"} placeholder={"Upload Resume"} />
                                        </div>
                                        <div className='submit_btn'>
                                            <Button>Apply</Button>
                                        </div>
                                    </div>
                                </Animation> 
                            : null}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default DescriptionPage;