import React from 'react';
import { Link } from 'react-router-dom';
import useCurrentWidth from '../../hooks/useGrid';

function MainPart(props) {

    
    const percentage = useCurrentWidth().percentage

    return (
        <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>

            <div className='career_main_wrapper' style={{ width: percentage * 39 }}>
                <div className='title'>
                    <div>Job Openings</div>
                </div>

                <div className='items'>
                    <div className='item'>
                        <div className='title_sub'>Java Developer</div>
                        <div className='des'>
                            <Link to={'/career/description'}>Description</Link>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='title_sub'>UX Designer</div>
                        <div className='des'>
                            <Link to={'/career/description'}>Description</Link>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='title_sub'>Front-end Developer</div>
                        <div className='des'>
                            <Link to={'/career/description'}>Description</Link>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default MainPart;