import React from 'react';

import HeroParticles from './Animations/HeroParticles';

import Animation from './Animations/Animation';

function PageTitle({ title }) {

    const animate = {
        visible: {opacity: 1, scale: 1, transition: { duration: .4 }},
        hidden: { opacity: 0, scale: 0 }
    }

    return (
        <div className='page_title_wrapper_with_particles'>
            <div className='page_title'>
                <div className='title'>
                    <h1> {title} </h1>
                </div>
            </div>
            <HeroParticles />
        </div>
    );
}

export default PageTitle;