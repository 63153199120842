import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";





function Animation(props) {

  const squareVariants = {
      visible:  props.animate.visible,
      hidden: props.animate.hidden
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariants}
      className="animation_wrapper"
      style={{...props.style}}
    >
        {props.children}
    </motion.div>
  );
}


export default Animation