import React, { useEffect } from 'react';
// import useCurrentWidth from '../hooks/useGrid';

import PageTitle from '../Components/PageTitle';
import TabMenu from '../Components/Tabs/TabMenu';

import Header from '../Components/Header';
import Footer from '../Components/Footer';

import ResearchPageAboutTab from '../Components/ResearchPageComponents/ResearchPageAboutTab';
import ResearchPageLibraryTab from '../Components/ResearchPageComponents/ResearchPageLibraryTab';
import ResearchPageVideoTab from '../Components/ResearchPageComponents/ResearchPageVideoTab';

function ResearchPage(props) {


    useEffect(() => {
        document.body.classList.remove('bg_white');
    }, [])

    // const percentage = useCurrentWidth().percentage

    const tabs = [{label: 'About'}, {label: 'Library'}, {label: "Video"}]
    const tabpanels = [
                        {component: <ResearchPageAboutTab />, index: 0}, 
                        {component: <ResearchPageLibraryTab />, index: 1},
                        {component: <ResearchPageVideoTab />, index: 2}
                    ]

    return (
       <>

            <Header />
       
            <div className='page_wrapper'>
                <PageTitle title="Research" />
                <TabMenu tabs={tabs} tabpanels={tabpanels} />
            </div>

            <div className='mb_150'></div>

            <Footer />

       </>
    );
}

export default ResearchPage;
