import React from 'react';
import useCurrentWidth from './hooks/useGrid';

import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";

import './Styles/index.scss'


// import main layout
import MainLayout from './Containers/Layout/MainLayout';
// import NotFound from './Pages/NotFound';

import ResearchPage from './Pages/ResearchPage';
import BlogPage from './Pages/BlogPage';
import CareerPage from './Pages/CareerPage'

import PageLoader from './Components/Loaders/PageLoader';


function App(props) {

  const percentage = useCurrentWidth().percentage
  const style = {
      width: percentage * 4
  }

  return (
    <HashRouter>
      <React.Suspense fallback={<PageLoader />}>

          {/* <div className='grid_wrapper'>
              <div className='grid' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
                  <div style={style}></div>
              </div>
          </div> */}
            
          <Routes>
            <Route path="*" element={<MainLayout />} />
            <Route path="/research/*" element={<ResearchPage />} />
            <Route path="/blog/*" element={<BlogPage />} />
            <Route path="/career/*" element={<CareerPage />} />
          </Routes>
            

      </React.Suspense>
    </HashRouter>
  );
}

export default App;