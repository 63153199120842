import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import Footer from '../Components/Footer';
import Header from '../Components/Header';

import BlogPageBlogs from '../Components/BlogPageComponents/BlogPageBlogs';
import SingleBlogPage from '../Components/BlogPageComponents/SingleBlogPage';

import PageLoader from '../Components/Loaders/PageLoader'

function BlogPage(props) {

    useEffect(() => {
        document.body.classList.add('bg_white');
    }, [])


    return (
        <div className='blog_page'>

            <Header />


                <React.Suspense fallback={<PageLoader />}>
                    <Routes>

                        <Route path="/" element={<BlogPageBlogs />} />
                        <Route path="/item" element={<SingleBlogPage />} />

                    </Routes>
                </React.Suspense>


            <Footer />
            
        </div>
    );
}

export default BlogPage;