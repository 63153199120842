import React from 'react';
import useCurrentWidth from '../../hooks/useGrid';
import { Link } from 'react-router-dom';

import bg_image from '../../assets/PageContent/blog_head_2.png'
import BlogPageHead from './BlogPageHead';
import Animation from '../Animations/Animation';
import BlogElement from './BlogElement';

import blogs from './blogs';

function SingleBlogPage(props) {

    const percentage = useCurrentWidth().percentage;

    const animate = {
        visible: {opacity: 1, scale: 1, transition: { duration: .4 }},
        hidden: { opacity: 0, scale: 0 }
    }

    return (
        <div className='single_blog_page'>

            <BlogPageHead image={bg_image} />

            <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>

                <div className='blog_details'>
                    <div className='wrapper' style={{ width: percentage * 39, margin: 'auto' }}>

                        <div className='head'>
                            <div className='title'>
                                Vorld collaboration in NFTs, Games, and the Metaverse
                            </div>
                            <div className='sub_details'>
                                February 7, 2022  <span>·</span>  Hari Prashad Kamal  <span>·</span>  4 mins read
                            </div>
                        </div>

                        <div className='text_section'>
                            <div className='section'>
                                The Filecoin ecosystem and Polygon Studios are delighted to announce a collaboration to drive growth in the 
                                non-fungible token (NFT), games, and meta verse space. To kickstart the collaboration, the two ecosystems have 
                                developed tutorials, integration grants, and hackathons aimed at helping developers and NFT projects in the Polygon 
                                network to integrate with InterPlanetary File System (IPFS) and Filecoin.
                            </div>
                            <div className='section'>
                                Originally conceived as a secure Layer 2 solution to address pain points like high gas fees and slow block validation 
                                times on popular blockchains like Ethereum, Polygon has gained traction with blockchain game developers and NFT 
                                projects. To cater to this growing segment, Polygon Studios was launched with the goal of incubating and 
                                supporting Web 3.0-powered game developers, and NFT projects.
                            </div>
                            <div className='section'>
                                Similarly, a growing number of projects in the play-to-earn (P2E) and NFT space have also chosen to use IPFS for 
                                content addressing and Filecoin for provable and decentralized storage to store metadata with rich media such as 
                                videos.
                            </div>
                            <div className='section title'>
                                Combining Filecoin’s durability and Polygon’s scalability opens new use cases for developers
                            </div>
                            <div className='section'>
                                In August 2021, we announced that Polygon would offer free storage costs for projects looking to build on the 
                                Filecoin-Polygon bridge developed by the Textile team. The bridge enables developers and Polygon application 
                                users to easily store data from any Polygon address on the IPFS network without any new signups, secrets, 
                                or API keys.
                            </div>
                            <div className='section'>
                                Filecoin and Polygon also jointly-organized the Mars Hackathon, which ran from August to November 2021. The virtual 
                                hackathon saw more than 1,500 developers coming together to build new applications using the Filecoin-Polygon bridge, 
                                including use cases like storage, the metaverse, entertainment, digital art, and more.
                            </div>
                            <div className='section'>
                                Notable projects from the hackathon included Blockspace, a decentralized file storage and sharing platform built on 
                                top of IPFS that mirrors traditional cloud providers like Google Drive, and Slick, a decentralized alternative to 
                                Patreon.
                            </div>
                            <div className='section title'>
                                What You Need to Know about Vorld Product Design Interview and What to do about it
                            </div>
                            <div className='section'>
                                Vinyl hella hot chicken aesthetic, intelligentsia raclette gentrify activated charcoal VHS. Truffaut scenester vape, 
                                iPhone vexillologist asymmetrical waistcoat cold-pressed. Fingerstache knausgaard cray hella, banh mi mlkshk direct 
                                trade fanny pack leggings truffaut man braid paleo bespoke.
                            </div>
                            <div className='section'>
                                Authentic vexillologist thundercats, kale chips next level flannel activated charcoal keffiyeh single-origin 
                                coffee lo-fi swag stumptown marfa dreamcatcher. Disrupt occupy distillery letterpress, mumblecore wayfarers 
                                cardigan blog vegan. Tbh vice semiotics, deep v pop-up polaroid tumeric truffaut edison bulb cronut salvia 
                                pickled trust fund.
                            </div>
                        </div>


                    </div>
                </div>

            </div>


            <div className='related_news'>
                <div className='page_content' style={{ paddingLeft: percentage * 2, paddingRight: percentage * 2 }}>
                    <div className='related_blogs blog_items'>

                        <div className='title'>
                            Related News
                        </div>

                        <div className='wrapper'>

                            {blogs && blogs.length ? 
                                blogs.filter((item, idx) => idx < 4).map((key, idx) => {
                                    return(
                                        <Animation animate={animate} key={idx} style={{ width: percentage * 14 }}>
                                            <Link to={"/blog/item"}>
                                                <BlogElement data={key} />
                                            </Link>
                                        </Animation>
                                    )
                                })
                            : null}

                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default SingleBlogPage;